import { default as _91slug_93CHXPNWg1QDMeta } from "/home/forge/lastikoto.com/pages/[slug].vue?macro=true";
import { default as addressAOQce9eFFLMeta } from "/home/forge/lastikoto.com/pages/account/address.vue?macro=true";
import { default as _91id_931LFgdcymdxMeta } from "/home/forge/lastikoto.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93YjUcuPHeauMeta } from "/home/forge/lastikoto.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexEIANxtv9Y3Meta } from "/home/forge/lastikoto.com/pages/account/orders/index.vue?macro=true";
import { default as reviewKsO1X9D4FcMeta } from "/home/forge/lastikoto.com/pages/account/review.vue?macro=true";
import { default as _91id_93FHpJApWNxxMeta } from "/home/forge/lastikoto.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexODlDNMWPnMMeta } from "/home/forge/lastikoto.com/pages/account/statement/index.vue?macro=true";
import { default as userWSryyMjZDZMeta } from "/home/forge/lastikoto.com/pages/account/user.vue?macro=true";
import { default as wishlistorSNseWyxGMeta } from "/home/forge/lastikoto.com/pages/account/wishlist.vue?macro=true";
import { default as cartRnDTjQCdtdMeta } from "/home/forge/lastikoto.com/pages/cart.vue?macro=true";
import { default as indexO53fpjcUrAMeta } from "/home/forge/lastikoto.com/pages/checkout/index.vue?macro=true";
import { default as payment4OU4tJmdWSMeta } from "/home/forge/lastikoto.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientjyaF3juK9gMeta } from "/home/forge/lastikoto.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/lastikoto.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestCiNoy9Oyp0Meta } from "/home/forge/lastikoto.com/pages/customer/request.vue?macro=true";
import { default as successwg8RNbLWSnMeta } from "/home/forge/lastikoto.com/pages/customer/success.vue?macro=true";
import { default as iletisimED08NjJ853Meta } from "/home/forge/lastikoto.com/pages/iletisim.vue?macro=true";
import { default as indexNXKxczU0u7Meta } from "/home/forge/lastikoto.com/pages/index.vue?macro=true";
import { default as loginywf3PMvaaQMeta } from "/home/forge/lastikoto.com/pages/login.vue?macro=true";
import { default as offerR8pwxHdShcMeta } from "/home/forge/lastikoto.com/pages/offer.vue?macro=true";
import { default as statusQrZBDaMnM1Meta } from "/home/forge/lastikoto.com/pages/order/status.vue?macro=true";
import { default as trackS4yN2qYqwMMeta } from "/home/forge/lastikoto.com/pages/order/track.vue?macro=true";
import { default as forgotJKcfTO614qMeta } from "/home/forge/lastikoto.com/pages/password/forgot.vue?macro=true";
import { default as resetUmjUFkKvwMMeta } from "/home/forge/lastikoto.com/pages/password/reset.vue?macro=true";
import { default as payment_45notificationTCEq3yfXBQMeta } from "/home/forge/lastikoto.com/pages/payment-notification.vue?macro=true";
import { default as summaryIW9LLXOwXXMeta } from "/home/forge/lastikoto.com/pages/payment/summary.vue?macro=true";
import { default as register5JAhbLnvYmMeta } from "/home/forge/lastikoto.com/pages/register.vue?macro=true";
import { default as searchiuJBKdhEFOMeta } from "/home/forge/lastikoto.com/pages/search.vue?macro=true";
import { default as locationsSbehz1eLeBMeta } from "/home/forge/lastikoto.com/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93CHXPNWg1QDMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressAOQce9eFFLMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_931LFgdcymdxMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93YjUcuPHeauMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexEIANxtv9Y3Meta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewKsO1X9D4FcMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93FHpJApWNxxMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexODlDNMWPnMMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userWSryyMjZDZMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistorSNseWyxGMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/lastikoto.com/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexO53fpjcUrAMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: payment4OU4tJmdWSMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/lastikoto.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/lastikoto.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/lastikoto.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/lastikoto.com/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexNXKxczU0u7Meta || {},
    component: () => import("/home/forge/lastikoto.com/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginywf3PMvaaQMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerR8pwxHdShcMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/lastikoto.com/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/lastikoto.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotJKcfTO614qMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetUmjUFkKvwMMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/lastikoto.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/lastikoto.com/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: register5JAhbLnvYmMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchiuJBKdhEFOMeta || {},
    component: () => import("/home/forge/lastikoto.com/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/lastikoto.com/modules/locationsPage/pages/locations.vue")
  }
]